// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/fonts/icomoon.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Berkshire+Swash);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"icomoon\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}html{font-size:62.5%}body{margin:0;padding:0;font-family:\"Nunito\",sans-serif,\"Berkshire Swash\",cursive;font-size:1.4rem}h1{font-size:1.8rem;font-weight:400}h2{font-size:3.2rem;font-weight:400}h3{font-size:2.4rem}ul{list-style-type:none;margin:0;padding:0}ul li{margin:0;padding:0}.mobile-container{padding-left:5.6%;padding-right:5.6%}", "",{"version":3,"sources":["webpack://./src/components/App.scss"],"names":[],"mappings":"AAGA,WACE,qBAAA,CACA,0DAAA,CAGF,KACE,eAAA,CAGF,KACE,QAAA,CACA,SAAA,CACA,yDAAA,CACA,gBAAA,CAGF,GACE,gBAAA,CACA,eAAA,CAGF,GACE,gBAAA,CACA,eAAA,CAGF,GACE,gBAAA,CAGF,GACE,oBAAA,CACA,QAAA,CACA,SAAA,CAEA,MACE,QAAA,CACA,SAAA,CAIJ,kBACE,iBAAA,CACA,kBAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Berkshire+Swash');\n@import url('https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700');\n\n@font-face {\n  font-family: 'icomoon';\n  src: url('../../assets/fonts/icomoon.woff') format('woff');\n}\n\nhtml {\n  font-size: 62.5%;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Nunito', sans-serif, 'Berkshire Swash', cursive;\n  font-size: 1.4rem;\n}\n\nh1 {\n  font-size: 1.8rem;\n  font-weight: 400;\n}\n\nh2 {\n  font-size: 3.2rem;\n  font-weight: 400;\n}\n\nh3 {\n  font-size: 2.4rem;\n}\n\nul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n\n  li {\n    margin: 0;\n    padding: 0;\n  }\n}\n\n.mobile-container {\n  padding-left: 5.6%;\n  padding-right: 5.6%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
